<template>
    <a-modal
        v-model:visible="visible"
        title="新增apk"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="600px"
    >
        <a-form
            :model="formData"
            ref="formRef"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="apk名称"
                name="versionName"
                :rules="[
                    { required: true, message: '请输入apk名称' },
                    { validator: checkSpaceAll },
                ]"
            >
                <a-input v-model:value="formData.versionName" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="apk编码"
                name="versionCode"
                :rules="[{ required: true, message: '请输入apk编码' }]"
            >
                <a-input v-model:value="formData.versionCode" />
            </a-form-item>

            <a-form-item
                label="apk描述"
                name="versionDescribe"
                :rules="[
                    { required: true, message: '请输入apk描述' },
                    { validator: checkSpaceAll },
                ]"
            >
                <a-textarea
                    v-model:value="formData.versionDescribe"
                    placeholder="最多可输入800字"
                    :rows="4"
                    :maxlength="800"
                />
            </a-form-item>

            <a-form-item
                label="apk上传"
                name="fileId"
                :rules="[{ required: true, message: '请上传apk文件' }]"
            >
                <UploadFile
                    accept=".apk"
                    businessType="MOBILE_APP"
                    @onSuccess="data => (formData.fileId = data?.id)"
                ></UploadFile>
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiApkAdd } from "@/api";
import Select from "@/components/Select";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import UploadFile from "@/components/UploadFile";
export default defineComponent({
    props: ["upDateId"],
    emits: ["eventUpdateOk"],
    components: {
        Select,
        UploadFile,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            formRef: null,
            formData: {
                id: "",
                versionName: "",
                versionCode: "",
                versionDescribe: "",
                fileId: "",
            },
        });

        const handleCancel = () => {
            state.formRef.resetFields();
            state.visible = false;
            emit("update:upDateId", null);
        };

        const onFinish = async () => {
            const { versionDescribe, fileId } = state.formData;
            let res = await apiApkAdd({
                ...state.formData,
                fileId,
                versionDescribe: [versionDescribe],
            });
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const checkSpaceAll = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        watch(
            () => props.upDateId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkSpaceAll,
        };
    },
});
</script>
