<template>
    <div class="container">
        <UpDateModal v-model:upDateId="upDateId" @eventUpdateOk="eventUpdateOk" />
       
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1200 }"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'fileBytes'">
                        {{ (+record?.fileBytes / 1024 / 1024).toFixed(2) }} MB
                    </template>
                    <template v-if="column.dataIndex === 'versionDescribe'">
                        {{ record?.versionDescribe.join(",") }}
                    </template>
                    <template v-if="column.dataIndex === 'published'">
                        <a-tag :color="record?.published ? 'green' : ''">
                            {{ record?.published ? "已发布" : "未发布" }}
                        </a-tag>
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="deleteRow(record.id)" v-perm="'btn:post-delete'">删除</a>
                            <a @click="() => handlePublish(record.id)" v-perm="'btn:post-edit'">
                                发布
                            </a>
                            <a
                                :href="`${baseURL}/web/sys-mobile-app/download/${record?.id}`"
                                v-perm="'btn:post-delete'"
                            >
                                下载
                            </a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-button></a-button>
                        <a-button
                            v-perm="'btn:post-add'"
                            type="primary"
                            @click="() => openUpDateModal('')"
                        >
                            新增
                        </a-button>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import UpDateModal from "./upDateModal";
import { confirmModal, showMessage } from "@/utils/common";
import { apiApkList, apiApkDelete, apiApkPublish } from "@/api";
import { baseURL } from "@/utils/config";

export default defineComponent({
    components: {
        UpDateModal,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "版本编码",
                dataIndex: "versionCode",
                width: 180,
            },
            {
                title: "版本名称",
                dataIndex: "versionName",
            },
            {
                title: "版本描述",
                dataIndex: "versionDescribe",
            },
            {
                title: "上传时间",
                dataIndex: "createdTime",
            },
            {
                title: "文件名",
                dataIndex: "fileName",
            },
            {
                title: "文件大小",
                dataIndex: "fileBytes",
            },
            {
                title: "发布状态",
                dataIndex: "published",
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 130,
            },
        ];

        const state = reactive({
            upDateId: null,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiApkList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        getTabelData();

        const openUpDateModal = id => {
            state.upDateId = id;
        };

        const deleteRow = id => {
            confirmModal({
                type: "warning",
                content: "apk删除后将无法恢复，是否确认删除该apk？",
                onOk: async () => {
                    let res = await apiApkDelete(id);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const handlePublish = id => {
            confirmModal({
                type: "warning",
                content: "确定发布该版本apk？",
                onOk: async () => {
                    let res = await apiApkPublish(id);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const eventUpdateOk = () => {
            getTabelData();
        };

        return {
            columns,
            baseURL,
            ...toRefs(state),
            openUpDateModal,
            deleteRow,
            paginationChange,
            eventUpdateOk,
            handlePublish,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
.container {
    .table-content {
        background-color: #fff;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
